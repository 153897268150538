import React from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Navigation from './Navigation';
import styles from './styles/Layout.module.scss';
import '../styles/global.scss';

const Layout = ({children}) => (
    <>
        <Helmet>
            <link rel="shortcut icon" href="/favicon.ico" />
        </Helmet>
        <div id="modal-root" />
        <div className={styles.container}>
            <div className={styles.navigation}>
                <Navigation />
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    </>
);

export default Layout;

export const query = graphql`
fragment SiteMetadata on Site {
  siteMetadata {
    title
    description
    url
  }
}
`;

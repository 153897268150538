import React from 'react';
import {Link} from 'gatsby';
import styles from './styles/Navigation.module.scss';

const Navigation = () => (
    <nav>
        <ul className={styles.list}>
            <li className={styles.item}>
                <Link to="/" className={styles.link}>Jan Vytiska</Link>
            </li>
            <li className={styles.item}>
                <Link to="/paintings" className={styles.link}>paintings</Link>
            </li>
            <li className={styles.item}>
                <Link to="/drawings" className={styles.link}>drawings</Link>
            </li>
            <li className={styles.item}>
                <Link to="/videos" className={styles.link}>videos</Link>
            </li>
            <li className={styles.item}>
                <Link to="/info" className={styles.link}>info</Link>
            </li>
        </ul>
    </nav>
);

export default Navigation;
